import { createContext, useState } from 'react'

export const Navigation = createContext(null)

const NavigationContext = ({ children }) => {
    const [navigationItems, setNavigationItems] = useState([])

    return (
        <Navigation.Provider value={{ navigationItems, setNavigationItems }}>
            {children}
        </Navigation.Provider>
    )
}

export default NavigationContext