import '@/styles/style.scss'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import { Layout } from '@/components/layout'
import { useEffect } from 'react'
import NavigationContext from '@/components/NavigationContext'
import { useRouter } from 'next/router'

export default function App({ Component, pageProps }) {
  const router = useRouter()

  const handleRouteChange = () => {
    const texts = gsap.utils.toArray('.loadup')

    texts.forEach((text) => {
        gsap.fromTo(text, {
          autoAlpha: 0,
        }, {
          scrollTrigger: {
            trigger: text,
            once: true,
            start: 'top bottom',
          },
          duration: 1,
          autoAlpha: 1,
        })
    })

    ScrollTrigger.refresh()
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

    handleRouteChange()

    router.events.on('routeChangeComplete', () => {
      console.log('now')
      handleRouteChange()
    })

    return () => {
      router.events.off('routeChangeComplete', () => {
        handleRouteChange()
      })
    }
  }, [])

  return (
    <NavigationContext>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </NavigationContext>
  )
}
