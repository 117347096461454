import { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import { Navigation } from './NavigationContext'
import PageTransition from './PageTransition'
import { useRouter } from 'next/router'

export const Layout = ({ children }) => {
    const router = useRouter()
    const { navigationItems } = useContext(Navigation)
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [mobileMenuButton, setMobileMenuButton] = useState('menu')
    const [loading, setLoading] = useState(true)
    const [loadingWrapper, setLoadingWrapper] = useState(true)

    useEffect(() => {
        if (mobileMenuOpen) {
            setTimeout(() => {
                setMobileMenuButton('close')
            }, 100)
        } else {
            setMobileMenuButton('menu')
        }
    }, [mobileMenuOpen])

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false)
            }, 1800)

            setTimeout(() => {
                document.body.classList.remove('___loading')
                setLoadingWrapper(false)
            }, 2600)
        }
    }, [])

    return (
        <>
            <div className="loader-wrapper" style={{ display: loadingWrapper ? 'block' : 'none' }}>
                <div className="loader">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 899.49 162.59" fill={'#ffffff'}>
                        <path className="cls-1" d="M0,3.72H43.44l3.72,26.68h1.24C59.88,9.31,77.26,0,92.46,0c9.62,0,14.89,1.24,19.24,3.1l-8.69,45.92c-5.59-1.24-10.55-2.48-17.38-2.48-11.17,0-24.82,6.83-32.27,26.06v86.26H0V3.72Z"/>
                        <path className="cls-1" d="M129.63,3.72h53.37V158.87h-53.37V3.72Z"/>
                        <path className="cls-1" d="M200.93,3.72h43.44l3.72,18.62h1.24c12.41-11.79,27.92-22.34,49.02-22.34,34.13,0,48.41,24.82,48.41,63.3v95.57h-53.37V70.12c0-19.86-4.97-24.82-15.51-24.82-9.93,0-15.51,4.34-23.58,11.79v101.77h-53.37V3.72Z"/>
                        <path className="cls-1" d="M364.69,140.87l23.58-32.89c14.27,10.55,26.99,15.51,37.86,15.51s15.51-3.1,15.51-9.31c0-8.07-14.27-11.79-29.79-18-18-7.14-39.1-20.48-39.1-45.92,0-30.41,24.82-50.27,61.44-50.27,25.44,0,43.44,10.24,57.09,20.48l-23.58,31.65c-11.17-8.07-22.03-13.03-31.65-13.03-9,0-13.65,2.48-13.65,8.69,0,8.07,13.65,11.17,29.17,16.75,18.62,6.83,39.72,18.62,39.72,46.54s-22.96,51.51-66.4,51.51c-19.86,0-44.06-8.69-60.19-21.72Z"/>
                        <path className="cls-1" d="M509.22,3.72h43.44l3.72,18.93h1.24c12.41-12.1,25.44-22.65,46.54-22.65s34.44,8.69,42.82,24.82c12.72-13.03,26.68-24.82,47.79-24.82,34.13,0,49.03,24.82,49.03,63.3v95.57h-53.37V70.12c0-19.86-4.97-24.82-15.51-24.82-6.21,0-13.65,3.72-21.72,11.79v101.77h-53.37V70.12c0-19.86-4.97-24.82-15.51-24.82-6.21,0-13.65,3.72-21.72,11.79v101.77h-53.37V3.72Z"/>
                        <path className="cls-1" d="M761.73,114.81c0-32.27,24.82-50.27,84.4-56.47-1.24-11.17-8.07-16.13-21.72-16.13-11.17,0-22.96,4.34-39.1,13.03l-18.62-34.75c21.72-13.03,44.06-20.48,68.26-20.48,40.34,0,64.54,21.72,64.54,72.61v86.26h-43.44l-3.72-14.89h-1.24c-12.41,11.17-26.06,18.62-42.82,18.62-29.17,0-46.54-22.34-46.54-47.79Zm84.4-3.72v-21.1c-25.44,3.72-33.51,11.79-33.51,20.48,0,7.14,4.96,11.17,14.27,11.17,8.69,0,13.65-4.34,19.24-10.55Z"/>
                    </svg>

                    <div className="bar" />
                </div>
            </div>

            <PageTransition>
                <div style={{ display: loading ? 'none' : 'block' }}>
                    <nav className={mobileMenuOpen ? 'mobile' : ''}>
                        <div className="global-wrapper">
                            <div className="nav-inner">
                                <Link href={'/'} aria-label="Studio Rinsma Logo">
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 899.49 162.59" fill={'#141414'}>
                                        <path className="cls-1" d="M0,3.72H43.44l3.72,26.68h1.24C59.88,9.31,77.26,0,92.46,0c9.62,0,14.89,1.24,19.24,3.1l-8.69,45.92c-5.59-1.24-10.55-2.48-17.38-2.48-11.17,0-24.82,6.83-32.27,26.06v86.26H0V3.72Z"/>
                                        <path className="cls-1" d="M129.63,3.72h53.37V158.87h-53.37V3.72Z"/>
                                        <path className="cls-1" d="M200.93,3.72h43.44l3.72,18.62h1.24c12.41-11.79,27.92-22.34,49.02-22.34,34.13,0,48.41,24.82,48.41,63.3v95.57h-53.37V70.12c0-19.86-4.97-24.82-15.51-24.82-9.93,0-15.51,4.34-23.58,11.79v101.77h-53.37V3.72Z"/>
                                        <path className="cls-1" d="M364.69,140.87l23.58-32.89c14.27,10.55,26.99,15.51,37.86,15.51s15.51-3.1,15.51-9.31c0-8.07-14.27-11.79-29.79-18-18-7.14-39.1-20.48-39.1-45.92,0-30.41,24.82-50.27,61.44-50.27,25.44,0,43.44,10.24,57.09,20.48l-23.58,31.65c-11.17-8.07-22.03-13.03-31.65-13.03-9,0-13.65,2.48-13.65,8.69,0,8.07,13.65,11.17,29.17,16.75,18.62,6.83,39.72,18.62,39.72,46.54s-22.96,51.51-66.4,51.51c-19.86,0-44.06-8.69-60.19-21.72Z"/>
                                        <path className="cls-1" d="M509.22,3.72h43.44l3.72,18.93h1.24c12.41-12.1,25.44-22.65,46.54-22.65s34.44,8.69,42.82,24.82c12.72-13.03,26.68-24.82,47.79-24.82,34.13,0,49.03,24.82,49.03,63.3v95.57h-53.37V70.12c0-19.86-4.97-24.82-15.51-24.82-6.21,0-13.65,3.72-21.72,11.79v101.77h-53.37V70.12c0-19.86-4.97-24.82-15.51-24.82-6.21,0-13.65,3.72-21.72,11.79v101.77h-53.37V3.72Z"/>
                                        <path className="cls-1" d="M761.73,114.81c0-32.27,24.82-50.27,84.4-56.47-1.24-11.17-8.07-16.13-21.72-16.13-11.17,0-22.96,4.34-39.1,13.03l-18.62-34.75c21.72-13.03,44.06-20.48,68.26-20.48,40.34,0,64.54,21.72,64.54,72.61v86.26h-43.44l-3.72-14.89h-1.24c-12.41,11.17-26.06,18.62-42.82,18.62-29.17,0-46.54-22.34-46.54-47.79Zm84.4-3.72v-21.1c-25.44,3.72-33.51,11.79-33.51,20.48,0,7.14,4.96,11.17,14.27,11.17,8.69,0,13.65-4.34,19.24-10.55Z"/>
                                    </svg>
                                </Link>

                                <ul>
                                    {navigationItems?.map((item, index) => (
                                        <li key={index}>
                                            <Link href={`/${item.link === 'home' ? '' : item.link}`}>
                                                {item.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>

                                <button type="button" className="mobile-nav-opener" onClick={() => setMobileMenuOpen(old => !old)}>
                                    {mobileMenuButton}
                                </button>
                            </div>
                        </div>
                    </nav>

                    <div className={`mobile-nav ${mobileMenuOpen ? 'show' : ''}`}>
                        <div className="mobile-nav-inner">
                            <ul>
                                {navigationItems?.map((item, index) => (
                                    <li key={index}>
                                        <Link href={`/${item.link === 'home' ? '' : item.link}`}>
                                            <button onClick={() => setMobileMenuOpen(false)}>{item.title}</button>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <main>{children}</main>

                    <footer className={router.pathname.includes('project/') ? 'project-footer' : ''}>
                        <div className="footer-wrapper">
                            <div className="global-wrapper">
                                <div className="footer-inner">
                                    <div className="content-wrapper">
                                        <span className="footer-title">Work together?</span>
                                        <span className="footer-content">Did I inspire you with my website or would you like to work together? Contact me :)</span>
                                        <Link href={'/contact'}>
                                            <span>Contact me</span>
                                            <i className="fas fa-arrow-right-long" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </PageTransition>
        </>
    )
}