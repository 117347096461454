import { useState, useEffect } from "react"
import { useRouter } from 'next/router'

const PageTransition = ({ children }) => {
    const router = useRouter()
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        router.events.on('routeChangeStart', () => {
            setAnimate(true)
        })

        router.events.on('routeChangeComplete', () => {
            setTimeout(() => {
                setAnimate(false)
            }, 300)
        })

        return () => {
            router.events.off('routeChangeStart', () => {})
            router.events.off('routeChangeComplete', () => {})
        }
    }, [])

    return (
        <>
            <div className={`page-transition ${animate ? 'animate' : ''}`}>
                <div className="page-transition__inner" />
            </div>
        
            {!animate && (
                <div className="page-transition__content">
                    {children}
                </div>
            )}
        </>
    )
}

export default PageTransition